export enum EReduxActionTypes {
  INCREMENT_ENTHUSIASM = 'INCREMENT_ENTHUSIASM',
  DECREMENT_ENTHUSIASM = 'DECREMENT_ENTHUSIASM',
  SIGNED_IN = 'SIGNED_IN',
  SIGN_IN_FAILED = 'SIGN_IN_FAILED',
  SIGNED_UP = 'SIGNED_UP',
  SIGN_UP_FAILED = 'SIGN_UP_FAILED',
  SIGN_OUT = 'SIGN_OUT',
  TEAM_LOADING = 'TEAM_LOADING',
  TEAM_LOADED = 'TEAM_LOADED',
  TEAM_SET_COWORKER_CREATE_STATUS = 'TEAM_SET_COWORKER_CREATE_STATUS',
  TEAM_SET_COWORKER_UPDATE_STATUS = 'TEAM_SET_COWORKER_UPDATE_STATUS',
  TEAM_SET_COWORKER_DELETE_STATUS = 'TEAM_SET_COWORKER_DELETE_STATUS',
  COWORKER_CREATED = 'COWORKER_CREATED',
  COWORKER_UPADATED = 'COWORKER_UPADATED',
  SCHEDULE_LOADING = 'SCHEDULE_LOADING',
  SCHEDULE_LOADED = 'SCHEDULE_LOADED',
  SCHEDULE_UPDATE_DATERANGE = 'SCHEDULE_UPDATE_DATERANGE',
  SCHEDULE_SET_TASK_CREATE_STATUS = 'SCHEDULE_SET_TASK_CREATE_STATUS',
  SCHEDULE_SET_TASK_UPDATE_STATUS = 'SCHEDULE_SET_TASK_UPDATE_STATUS',
  SCHEDULE_SET_TASK_DELETE_STATUS = 'SCHEDULE_SET_TASK_DELETE_STATUS',
  SHARE_SCHEDULE_LOADED = 'SHARE_SCHEDULE_LOADED',
}

export enum Constants {
  JWT_TOKEN_KEY = 'jwtToken',
}

export type LoadStatus = 'Not_Start' | 'Loading' | 'Loaded' | 'Failed';

export interface IReduxBaseAction {
  type: EReduxActionTypes;
}
