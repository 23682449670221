import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from 'redux';
import * as actions from '../actions/user';
import SignIn from "../components/SignIn";
import { AppState } from "../reducers/root";
import { ActionType } from "typesafe-actions";

export type UserAction = ActionType<typeof actions>;

export function mapStateToProps(state: AppState) {
  return {
    signInStatus: state.user.signInStatus,
  }
}

export function mapDispatchToProps(dispatch: Dispatch<UserAction>) {
  return bindActionCreators({
    onSubmit: actions.signIn,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);