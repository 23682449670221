import * as React from 'react';
import { Breadcrumb, Table, Space, Button, Row, Col, Input, Switch, Modal, message } from 'antd';
import { Coworker, Contact } from '../types/coworker';
import TeamFormModal from './TeamFormModal';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { LoadStatus } from '../types';

export interface Props {
  isLoading: boolean;
  loadCoworkers: () => void;
  coworkers: Coworker[];
  onCreateCoworker: (name: string, contacts: Contact[]) => void;
  onUpdateCoworker: (id: string, name: string, contacts: Contact[]) => void;
  onDeleteCoworker: (id: string) => void;
  createCoworkerStatus: LoadStatus;
  updateCoworkerStatus: LoadStatus;
  deleteCoworkerStatus: LoadStatus;
}

function Team({
  loadCoworkers, isLoading, coworkers,
  onCreateCoworker, onUpdateCoworker, onDeleteCoworker,
  createCoworkerStatus, updateCoworkerStatus, deleteCoworkerStatus
}: Props) {
  const [ nameFilter, setNameFilter ] = React.useState('');
  const [ emptyEmailFilter, setEmptyEmailFilter ] = React.useState(false);
  const [ teamFormOpen, setTeamFormOpen ] = React.useState(false);
  const [ isUpdate, setIsUpdate ] = React.useState(false);
  const [ coworkerToUpdate, setCoworkerToUpdate ] = React.useState(coworkers[0]);

  message.config({
    top: 64
  });

  React.useEffect(() => {
    loadCoworkers();
  }, [loadCoworkers]);

  // Handle create coworker status update
  React.useEffect(() => {
    message.destroy();
    switch (createCoworkerStatus) {
      case 'Loading':
        message.loading("Creating new coworker.");
        break;
      case 'Loaded':
        message.success("Coworker is created successfully!");
        setTeamFormOpen(false);
        break;
      case 'Failed':
        message.error("Create new coworker failed.");
        break;
    }
  }, [createCoworkerStatus]);

  // Handle update coworker status update
  React.useEffect(() => {
    message.destroy();
    switch (updateCoworkerStatus) {
      case 'Loading':
        message.loading("Updating coworker.");
        break;
      case 'Loaded':
        message.success("Coworker is updated successfully!");
        setTeamFormOpen(false);
        break;
      case 'Failed':
        message.error("Update coworker failed.");
        break;
    }
  }, [updateCoworkerStatus]);

   // Handle delete coworker status update
  React.useEffect(() => {
    message.destroy();
    switch (deleteCoworkerStatus) {
      case 'Loading':
        message.loading("Deleting coworker.");
        break;
      case 'Loaded':
        message.success("Coworker is deleted successfully!");
        break;
      case 'Failed':
        message.error("Delete coworker failed.");
        break;
    }
  }, [deleteCoworkerStatus]);

  const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameFilter(event.target.value);
  };

  const onEmptyEmailFilterChange = (checked: boolean) => {
    setEmptyEmailFilter(checked);
  };

  const tableInfoRaw = coworkers
  .filter(coworker => nameFilter === '' || coworker.name.toLowerCase().indexOf(nameFilter.toLowerCase()) >= 0)
  .filter(coworker => !emptyEmailFilter || !coworker.contacts || coworker.contacts.length === 0);

  const tableInfo = tableInfoRaw
  .map((coworker, index) => ({
    key: index,
    name: coworker.name,
    email: coworker.contacts && coworker.contacts.length >= 1 ? coworker.contacts[0].value : '',
  }));

  const handleCreateCoworker = () => {
    setIsUpdate(false);
    setTeamFormOpen(true);
  };

  const handleEditCoworker = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const index = +event.currentTarget.dataset.coworker_id!;
    setCoworkerToUpdate(tableInfoRaw[index]);
    setIsUpdate(true);
    setTeamFormOpen(true);
  };

  const handleDeleteCoworker = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const index = +event.currentTarget.dataset.coworker_id!;
    Modal.confirm({
      title: `Are you sure delete this coworker - ${tableInfoRaw[index].name}?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        onDeleteCoworker(tableInfoRaw[index].id);
      },
      // tslint:disable-next-line: no-empty
      onCancel() {},
    });
  };

  const onCancel = () => {
    setTeamFormOpen(false);
  };

  const Actions = (_text: any, _record: Coworker, index: number) => (
    <Space size="middle">
      <Button data-coworker_id={index} onClick={handleEditCoworker}>Eidt</Button>
      <Button data-coworker_id={index} onClick={handleDeleteCoworker}>Delete</Button>
    </Space>
  );

  return (
    <>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Team</Breadcrumb.Item>
      </Breadcrumb>
      <Row align="middle" gutter={[8, 8]}>
        <Col>
          <Button onClick={handleCreateCoworker}>New</Button>
        </Col>
        <Col>
          <Input placeholder="Search Name" value={nameFilter} onChange={onFilterChange} />
        </Col>
        <Col>
          <Space>
            <label>Show Empty Email: </label>
            <Switch
              defaultChecked={false}
              onChange={onEmptyEmailFilterChange}
            />
          </Space>
        </Col>
      </Row>
      <Table dataSource={tableInfo} loading={isLoading}>
        <Table.Column title='Name' dataIndex='name'/>
        <Table.Column title='Email Address' dataIndex='email' />
        <Table.Column
          title="Action"
          key="action"
          render={Actions}
        />
      </Table>
      <TeamFormModal
        isUpdate={isUpdate}
        isOpen={teamFormOpen}
        coworker={coworkerToUpdate}
        onCreateCoworker={onCreateCoworker}
        onUpdateCoworker={onUpdateCoworker}
        onCancel={onCancel}
      />
    </>
  );
}

export default Team;