import { ConnectedRouter } from 'connected-react-router';
import React, { Component } from 'react';
import TopNav from './containers/TopNav';
import { Switch, Route, Redirect } from 'react-router';
import Home from './containers/Home';
import SignIn from './containers/SignIn';
import SignUp from './containers/SignUp';
import ProtectedRoute from './helpers/ProtectedRoute';
import { history  }from './store';
import LoadingScreen from './components/LoadingScreen';
import { Dispatch, Action, bindActionCreators } from 'redux';
import * as userActions from './actions/user';
import { UserInfo } from './types/user';
import { connect } from 'react-redux';
import { callAPI } from './services/dataProxy';
import { Layout } from 'antd';
import GenericNotFound from './components/GenericNotFound';
import './App.css';
import Team from './containers/Team';
import Schedule from './containers/Schedule';
import ScheduleShare from './containers/ScheduleShare';

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    onLoaded: (userInfo: UserInfo) => dispatch(userActions.signedIn(userInfo)),
  }, dispatch);
}

interface Props {
  onLoaded: (userInfo: UserInfo) => void;
}

interface State {
  loaded: boolean;
}

class App extends Component<Props, State> {
  state: State = {
    loaded: false,
  };

  async componentDidMount() {
    const { onLoaded } = this.props;
    try {
      const response = await callAPI('/auth/load-session', 'GET', true);
      if (response.ok) {
        const body = await response.json();
        onLoaded(body.userInfo);
      }

      window.setTimeout(() => {
        this.setState({
          loaded: true,
        });
      }, 1000);
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.error(e);
      // TODO: redirect to error page
    }
  }

  render() {
    return (
      <ConnectedRouter history={history}>
          {
            !this.state.loaded ?
            <LoadingScreen /> :
            <Layout>
              <Layout.Header>
                <TopNav />
              </Layout.Header>
              <Layout.Content className="content">
                <Switch>
                  <Route exact={true} path="/">
                    <Home />
                  </Route>
                  <Route path="/login">
                    <SignIn />
                  </Route>
                  <Route path="/signup">
                    <SignUp />
                  </Route>
                  <Route path="/share/:id">
                    <ScheduleShare />
                  </Route>
                  <ProtectedRoute path="/schedule">
                    <Schedule />
                  </ProtectedRoute>
                  <ProtectedRoute path="/team">
                    <Team />
                  </ProtectedRoute>
                  <Route path="/404">
                    <GenericNotFound />
                  </Route>
                  <Redirect to="/404" />
                </Switch>
              </Layout.Content>
              <Layout.Footer className="footer">Litaff @2020 Created by Xiao Hu</Layout.Footer>
            </Layout>
          }
      </ConnectedRouter>
    );
  }
}

export default connect(null, mapDispatchToProps)(App);