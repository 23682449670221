import { ActionType } from 'typesafe-actions';
import { EReduxActionTypes, LoadStatus } from '../types';
import * as actions from '../actions/team';
import { Coworker } from '../types/coworker';

export type TeamState = Readonly<{
  coworkers: Coworker[];
  loading: boolean;
  createCoworkerStatus: LoadStatus;
  updateCoworkerStatus: LoadStatus;
  deleteCoworkerStatus: LoadStatus;
}>;

const initialState : TeamState = {
  coworkers: [],
  loading: false,
  createCoworkerStatus: 'Not_Start',
  updateCoworkerStatus: 'Not_Start',
  deleteCoworkerStatus: 'Not_Start',
}

export type TeamAction = ActionType<typeof actions>;

export default function user(state: TeamState = initialState, action: TeamAction): TeamState {
  switch (action.type) {
    case EReduxActionTypes.TEAM_LOADED:
      return { ...state, coworkers: action.payload.coworkers, loading: false};
    case EReduxActionTypes.TEAM_LOADING:
      return { ...state, loading: true};
    case EReduxActionTypes.TEAM_SET_COWORKER_CREATE_STATUS:
      return { ...state, createCoworkerStatus: action.payload.status };
    case EReduxActionTypes.TEAM_SET_COWORKER_UPDATE_STATUS:
      return { ...state, updateCoworkerStatus: action.payload.status };
    case EReduxActionTypes.TEAM_SET_COWORKER_DELETE_STATUS:
      return { ...state, deleteCoworkerStatus: action.payload.status };
    default:
      return state;
  }
}
