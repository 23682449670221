import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { StateType } from 'typesafe-actions';
import user from './user';
import team from './team';
import schedule from './schedule';

const createRootReducer = (history: History) => combineReducers({
  router: connectRouter(history),
  user,
  team,
  schedule,
});

export type AppState = StateType<ReturnType<typeof createRootReducer>>;

export default createRootReducer;