import * as React from 'react';
import { Modal, Form, DatePicker} from 'antd';
import './ShareScheduleFormModal.css';
import { Moment } from 'moment';

export interface Props {
  isOpen: boolean;
  defaultStartDate: Moment;
  defaultEndDate: Moment;
  onShareTask: (startDate: Moment, endDate: Moment) => void;
  onCancel: () => void;
}

function ShareScheduleFormModal({
  isOpen, defaultStartDate, defaultEndDate, onShareTask, onCancel }: Props) {
  const [form] = Form.useForm();
  const initialShareScheduleFormData = {
    dateRange: [defaultStartDate, defaultEndDate],
  };

  React.useEffect(() => {
    if (isOpen) {
      form.resetFields();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleOk = () => {
    form.validateFields()
    .then(values => {
      onShareTask(values.dateRange[0], values.dateRange[1]);
    })
    .catch(() => {/** ignore */});
  };

  const handleCancel = () => {
    onCancel();
  };

  // Render elements
  return (
    <Modal
      title='Share Schedule'
      visible={isOpen}
      maskClosable={false}
      closable={false}
      okText='Share'
      cancelText='Cancel'
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form
        layout='vertical'
        name="share_schedule_form"
        form={form}
        initialValues={ initialShareScheduleFormData }
      >
        <Form.Item
          label='Please select the date range to share'
          name="dateRange"
          rules={[
            { type: 'array', required: true, message: 'Please select date range.' },
          ]}
        >
          <DatePicker.RangePicker className={'share-schedule-form-modal__datepicker'}/>
        </Form.Item>
      </Form>
    </Modal>

  );
}

export default ShareScheduleFormModal;