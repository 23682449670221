import { Col, Row, Typography } from 'antd';
import * as React from 'react';

const { Title } = Typography;


function Home() {
  return (
    <Row>
      <Col span={8}/>
      <Col>
        <Typography>
          <Title>Welcome to Litaff</Title>
          <Title level={4}>Manage your little staff easily.</Title>
        </Typography>
      </Col>
    </Row>
  );
}

export default Home;
