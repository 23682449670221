import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from 'redux';
import { AppState } from "../reducers/root";
import ScheduleShare from "../components/ScheduleShare";
import * as scheduleActions from '../actions/schedule';
import { ActionType } from "typesafe-actions";

export type ScheduleAction = ActionType<typeof scheduleActions>;

export function mapStateToProps(state: AppState) {
  return {
    tasks: state.schedule.tasks,
    startDate: state.schedule.startDate,
    endDate: state.schedule.endDate,
  };
}

export function mapDispatchToProps(dispatch: Dispatch<ScheduleAction>) {
  return bindActionCreators({
    loadSchedule: scheduleActions.loadShareSchedule,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleShare);