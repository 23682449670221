import * as React from 'react';
import { Input, Modal, Form, DatePicker} from 'antd';
import './ScheduleFormModal.css';
import moment, { Moment } from 'moment';
import { TaskFormData } from '../types/taskFormData';
import { Coworker } from '../types/coworker';
import CoworkersInput from './CowokersInput';

export interface Props {
  isOpen: boolean;
  isUpdate: boolean;
  initialTaskFormData: TaskFormData;
  availableCoworkers: Coworker[];
  onNewTaskSubmit?: (newTask: TaskFormData) => void;
  onUpdateTaskSubmit?: (updatedTask: TaskFormData, isFieldsTouched: boolean) => void;
  onCancel: () => void;
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const range = (start: number, end: number) => {
  const result = [];
  for (let i = start; i <= end; i++) {
    result.push(i);
  }
  return result;
};

function ScheduleFormModal({
  isOpen, isUpdate, initialTaskFormData, availableCoworkers,
  onNewTaskSubmit, onUpdateTaskSubmit, onCancel }: Props) {
  const [form] = Form.useForm();
  const okLabel = isUpdate ? 'Update' : 'Create';
  const title = isUpdate ? "Update Task" : "Create New Task";
  const [taskDuration, setTaskDuration] =
    React.useState(moment.duration(initialTaskFormData.endTime.diff(initialTaskFormData.startTime)));
  const [reminderDuration, setReminderDuration] =
    React.useState(moment.duration(initialTaskFormData.startTime.diff(initialTaskFormData.reminderTime)));

  React.useEffect(() => {
    // The ScheduleFormModal is reused, so the state need to be reset everytime.
    setTaskDuration(moment.duration(initialTaskFormData.endTime.diff(initialTaskFormData.startTime)));
    setReminderDuration(moment.duration(initialTaskFormData.startTime.diff(initialTaskFormData.reminderTime)));
  }, [initialTaskFormData]);

  React.useEffect(() => {
    if (isOpen) {
      form.resetFields();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const availableCoworkersMap = availableCoworkers.reduce((map: Map<string, Coworker>, coworker: Coworker) => {
    map.set(coworker.id, coworker);
    return map;
  }, new Map());

  const handleOk = () => {
    form.validateFields()
    .then(values => {
      const updatedTaskFormData: TaskFormData = {
        id: initialTaskFormData.id,
        subject: values.subject,
        startTime: values.startTime,
        endTime: values.endTime,
        reminderTime: values.reminderTime,
        details: values.details || '',
        resources: values.resources,
        version: initialTaskFormData.version, // version is not editable
      };
      if (isUpdate) {
        onUpdateTaskSubmit!(updatedTaskFormData, form.isFieldsTouched());
      } else {
        onNewTaskSubmit!(updatedTaskFormData);
      }
    })
    .catch(() => {/** ignore */});
  };

  const onStartTimeChange = (startTime: any, _formatString: string) => {
    const newStartTime: Moment = startTime;
    form.setFieldsValue({
      'reminderTime': moment(newStartTime).subtract(reminderDuration)
    });
    form.setFieldsValue({
      'endTime': moment(newStartTime).add(taskDuration)
    });
  };

  const onEndTimeChange = (endTime: any, _formatString: string) => {
    const newEndTime: Moment = endTime;
    const curStartTime: Moment = form.getFieldValue("startTime");
    setTaskDuration(moment.duration(newEndTime.diff(curStartTime)));
  };

  const onReminderTimeChange = (reminderTime: any, _formatString: string) => {
    const newReminderTime: Moment = reminderTime;
    const curStartTime: Moment = form.getFieldValue("startTime");
    setReminderDuration(moment.duration(curStartTime.diff(newReminderTime)));
  };

  const handleCancel = () => {
    onCancel();
  };

  const disabledEndDate = (current: Moment) => {
    const curStartTime: Moment = form.getFieldValue("startTime");
    return current < curStartTime;
  };

  const disabledEndTime = (curTime: any) => {
    const curStartTime: Moment = form.getFieldValue("startTime");
    if (curStartTime.isSame(curTime, 'day') && curStartTime.isSame(curTime, 'hour')) {
      return {
        disabledHours: () => range(0, curStartTime.hours() - 1),
        disabledMinutes: () => range(0, curStartTime.minutes())
      };
    } else if (curStartTime.isSame(curTime, 'day')) {
      return {
        disabledHours: () => range(0, curStartTime.hours() - 1)
      };
    }

    return {};
  };

  const disabledReminderDate = (current: Moment) => {
    const curStartTime: Moment = form.getFieldValue("startTime");
    return current > curStartTime;
  };

  const disabledReminderTime = (curTime: any) => {
    const curStartTime: Moment = form.getFieldValue("startTime");
    if (curStartTime.isSame(curTime, 'day') && curStartTime.isSame(curTime, 'hour')) {
      return {
        disabledHours: () => range(curStartTime.hours() + 1, 24),
        disabledMinutes: () => range(curStartTime.minutes(), 60)
      };
    } else if (curStartTime.isSame(curTime, 'day')) {
      return {
        disabledHours: () => range(curStartTime.hours() + 1, 24)
      };
    }

    return {};
  };

  // Render elements
  return (
    <Modal
      title={title}
      centered={true}
      width={700}
      visible={isOpen}
      maskClosable={false}
      closable={false}
      okText={okLabel}
      cancelText='Cancel'
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form
        {...layout}
        name="task_form"
        form={form}
        initialValues={ initialTaskFormData }
      >
        <Form.Item
          label="Subject"
          name="subject"
          rules={[
            { required: true, message: 'Please input the subject.' },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Start Time"
          name="startTime"
          rules={[
            { type: 'object', required: true, message: 'Please input the start time.' },
          ]}
        >
          <DatePicker
            className={'schedule-form-modal__datepicker'}
            showTime={{ format: 'h:mm a', minuteStep: 15}}
            format={'YYYY-MM-DD h:mm a'}
            onChange={onStartTimeChange}
            allowClear={false}
          />
        </Form.Item>

        <Form.Item
          label="End Time"
          name="endTime"
          rules={[
            { type: 'object', required: true, message: 'Please input the end time.' },
            ({ getFieldValue }) => ({
              validator(_rule, value) {
                const startTime: Moment = getFieldValue('startTime');
                const endTime: Moment = value;
                if (!value || endTime.isAfter(startTime)) {
                  return Promise.resolve();
                }
                return Promise.reject('End Time should be after Start Time!');
              },
            }),
          ]}
        >
          <DatePicker
            className={'schedule-form-modal__datepicker'}
            showTime={{ format: 'h:mm a', minuteStep: 15}}
            format={'YYYY-MM-DD h:mm a'}
            disabledDate={disabledEndDate}
            disabledTime={disabledEndTime}
            onChange={onEndTimeChange}
            allowClear={false}
            showNow={false}
          />
        </Form.Item>

        <Form.Item
          label="Reminder Time"
          name="reminderTime"
          rules={[
            { type: 'object', required: true, message: 'Please select reminder time.' },
            ({ getFieldValue }) => ({
              validator(_rule, value) {
                const startTime: Moment = getFieldValue('startTime');
                const reminderTime: Moment = value;
                if (!value || reminderTime.isBefore(startTime)) {
                  return Promise.resolve();
                }
                return Promise.reject('Reminder Time should be before Start Time!');
              },
            }),
          ]}
        >
          <DatePicker
            className={'schedule-form-modal__datepicker'}
            placeholder="Select Time"
            showTime={{ format: 'h:mm a', minuteStep: 15 }}
            disabledDate={disabledReminderDate}
            disabledTime={disabledReminderTime}
            onChange={onReminderTimeChange}
            format={'YYYY-MM-DD h:mm a'}
            showNow={false}
            allowClear={false}
          />
        </Form.Item>

        <Form.Item
          label="Coworkers"
          name="resources"
        >
          <CoworkersInput availableCoworkersMap={availableCoworkersMap} />
        </Form.Item>

        <Form.Item
          label="Details"
          name="details"
        >
          <Input.TextArea placeholder="Add task details " autoSize={{ minRows: 8 }}/>
        </Form.Item>
      </Form>
    </Modal>

  );
}

export default ScheduleFormModal;