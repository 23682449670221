import { action } from 'typesafe-actions';
import { EReduxActionTypes, LoadStatus } from '../types';
import { Coworker, Contact } from '../types/coworker';
import { Dispatch } from 'redux';
import { callAPI } from '../services/dataProxy';
import { AppState } from '../reducers/root';

// Standard Actions

export const teamLoading = () => action(EReduxActionTypes.TEAM_LOADING);

export const teamLoaded = (coworkers: Coworker[]) => action(EReduxActionTypes.TEAM_LOADED, {
  coworkers
});

export const setCreateCoworkerStatus = (status: LoadStatus) => action(
  EReduxActionTypes.TEAM_SET_COWORKER_CREATE_STATUS,
  {
    status
  }
);

export const setUpdateCoworkerStatus = (status: LoadStatus) => action(
  EReduxActionTypes.TEAM_SET_COWORKER_UPDATE_STATUS,
  {
    status
  }
);

export const setDeleteCoworkerStatus = (status: LoadStatus) => action(
  EReduxActionTypes.TEAM_SET_COWORKER_DELETE_STATUS,
  {
    status
  }
);

// Async Actions below
export const loadCoworkers = () => async (dispatch: Dispatch, getState: () => any): Promise<void> => {
  try {
    dispatch(setCreateCoworkerStatus('Not_Start'));
    dispatch(setUpdateCoworkerStatus('Not_Start'));
    dispatch(setDeleteCoworkerStatus('Not_Start'));
    const state: AppState = getState();
    if (state.team.coworkers.length === 0) {
      // Display loading screen only when no coworker is loaded.
      dispatch(teamLoading());
    }
    const response = await callAPI('/resources', 'GET', true);
    if (response.ok) {
      const body = await response.json();
      // convert to coworker list
      const coworkers: Coworker[] = body.result.Items.map((item: any): Coworker => ({...item}));
      dispatch(teamLoaded(coworkers));
    } else {
      // TODO: add error handling
      dispatch(teamLoaded([]));
    }
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.error(e);
    // TODO: redirect to error page
  }
};

export const createCoworker = (name: string, contacts: Contact[]) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(setCreateCoworkerStatus('Loading'));
  try {
    const response = await callAPI('/resources', 'POST', true, {
      name,
      contacts,
    });
    if (response.ok) {
      dispatch(setCreateCoworkerStatus('Loaded'));
      dispatch(loadCoworkers() as any);
    } else {
      // TODO: add error handling
      dispatch(setCreateCoworkerStatus('Failed'));
    }
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.error(e);
    // TODO: redirect to error page
  }
};

export const updateCoworker = (id: string, name: string, contacts: Contact[]) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(setUpdateCoworkerStatus('Loading'));
  try {
    const response = await callAPI(`/resources/${id}`, 'PUT', true, {
      name,
      contacts,
    });
    if (response.ok) {
      dispatch(setUpdateCoworkerStatus('Loaded'));
      dispatch(loadCoworkers() as any);
    } else {
      dispatch(setUpdateCoworkerStatus('Failed'));
    }
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.error(e);
    // TODO: redirect to error page
  }
};

export const deleteCoworker = (id: string) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(setDeleteCoworkerStatus('Loading'));
  try {
    const response = await callAPI(`/resources/${id}`, 'DELETE', true);
    if (response.ok) {
      dispatch(setDeleteCoworkerStatus('Loaded'));
      dispatch(loadCoworkers() as any);
    } else {
      dispatch(setDeleteCoworkerStatus('Failed'));
    }
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.error(e);
    // TODO: redirect to error page
  }
};
