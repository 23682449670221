import * as React from 'react';
import FullCalendar, { EventInput } from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import { Task } from '../types/task';
import { useParams } from 'react-router-dom';

export interface Props {
  tasks: Task[];
  startDate: moment.Moment;
  endDate: moment.Moment;
  loadSchedule: (shareId: string) => void;
}

function ScheduleShare({ loadSchedule, tasks, startDate, endDate}: Props) {

  const { id } = useParams<{ id: string}>();
  React.useEffect(() => {
    loadSchedule(id);
  }, [id, loadSchedule]);

  const calendarRef = React.createRef<FullCalendar>();
  React.useEffect(() => {
    const calendarApi = calendarRef.current!.getApi();
    calendarApi.gotoDate(startDate.toDate());
    calendarApi.setOption('duration', { days: (endDate.diff(startDate, 'days') + 1) });
  }, [startDate, endDate, calendarRef]);

  const events: EventInput[] = tasks.map((task) => ({
    id: task.id,
    title: task.subject,
    start: task.startTime,
    end: task.endTime,
  }));

  return (
    <>
      <FullCalendar
        plugins={[ listPlugin ]}
        headerToolbar={{ end: ''}}
        nowIndicator={true}
        height={'auto'}
        initialView="list"
        ref={calendarRef}
        events={events}
      />
    </>
  );
}

export default ScheduleShare;