import * as React from 'react';
import { TaskResource } from "../types/task";
import { Coworker } from "../types/coworker";
import { Tag, AutoComplete } from "antd";
import "./CowokersInput.css";

interface Props {
  value?: TaskResource[];
  onChange?: (value: TaskResource[]) => void;
  availableCoworkersMap: Map<string, Coworker>;
}

function CoworkersInput({ value = [], onChange, availableCoworkersMap }: Props) {
  const [inputValue, setInputValue] = React.useState('');
  const curCoworkerMap = value.reduce((map: Map<string, TaskResource>, res: TaskResource) => {
    map.set(res.id, res);
    return map;
  }, new Map());

  const handleClose = (index: number) => {
    value.splice(index, 1);
    if (onChange) {
      onChange([...value]);
    }
  };

  const options = Array.from(availableCoworkersMap.values()).map(coworker => ({
    key: coworker.id,
    value: coworker.name,
  }));

  // TODO: create coworker on the fly
  // if (inputValue) {
  //   options.push({
  //     key: "NEW",
  //     value: `Create "${inputValue}"`,
  //   });
  // }

  const onSelect = (_name: string, optionData: any) => {
    const coworker = availableCoworkersMap.get(optionData.key);
    if (coworker && !curCoworkerMap.has(coworker.id)) {
      value.push({
        id: coworker.id,
        resourceType: coworker.resourceType,
        name: coworker.name
      });
    } else if (optionData.key === "NEW") {
      // TODO create new coworker
    }
    if (onChange) {
      onChange([...value]);
    }
    setInputValue("");
  };

  const onInputChange = (newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const filterOption = (_curInputValue: string, option: any) =>
    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;

  return (
    <>
      {
        value.map((coworker, index) => (
          <Tag
            className="coworkersinput-name-tag"
            key={`${coworker.id}-${index}`}
            color={ availableCoworkersMap.get(coworker.id) ? 'blue' : 'default' }
            closable={true}
            // tslint:disable-next-line: jsx-no-lambda
            onClose={() => handleClose(index)}
          >
            {coworker.name}
          </Tag>
        ))
      }
      <AutoComplete
        value={inputValue}
        options={!inputValue ? [] : options}
        onSelect={onSelect}
        onChange={onInputChange}
        filterOption={filterOption}
      />
    </>
  );
}

export default CoworkersInput;