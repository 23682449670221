import * as React from 'react';
import { Form, Input, Button, Layout } from 'antd';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import { SignInStatus } from '../types/user';

export interface Props {
  onSubmit: (history: History<History.PoorMansUnknown>, username: string, password: string) => void;
  signInStatus: SignInStatus;
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 8 },
};

function SignIn({ onSubmit, signInStatus }: Props) {
  const history = useHistory();
  const onFinish = (values: any) => {
    onSubmit(history, values.username, values.password);
  };

  return (
    <Layout>
      <Layout.Content>
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: false }}
          onFinish={onFinish}
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[
              { required: true, message: 'Please input your username!' },
              { type: 'email', message: 'The input is not a valid email.'}
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            {
              signInStatus === 'Failed' ?
              <p>Failed to signIn.</p>
              :
              null
            }
          </Form.Item>
        </Form>
      </Layout.Content>
    </Layout>
  );
}

export default SignIn;