import { AppState } from "../reducers/root";
import { connect } from "react-redux";
import AppBar from "../components/AppBar";
import { ActionType } from "typesafe-actions";
import * as actions from '../actions/user';
import { bindActionCreators, Dispatch } from "redux";

export type UserAction = ActionType<typeof actions>;

function mapStateToProps(state: AppState) {
  return {
    isAuthenticated: !!state.user.userInfo,
  };
}

export function mapDispatchToProps(dispatch: Dispatch<UserAction>) {
  return bindActionCreators({
    signOut: () => dispatch(actions.signOut()),
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AppBar);