import * as React from 'react';
import { Breadcrumb, Button, message, Modal, Popover } from 'antd';
import { Task } from '../types/task';
import moment, { Moment } from 'moment';
import ScheduleFormModal from './ScheduleFormModal';
import { TaskFormData } from '../types/taskFormData';
import { LoadStatus } from '../types';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Coworker } from '../types/coworker';
import FullCalendar, { DateSelectArg, DatesSetArg, EventApi, EventContentArg, EventInput } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import './Schedule.css';
import ShareScheduleFormModal from './ShareScheduleFormModal';

export interface Props {
  tasks: Task[];
  coworkers: Coworker[];
  startDate: Moment;
  endDate: Moment;
  isLoading: boolean;
  loadTasks: (startDate: Moment, endDate: Moment) => void;
  loadCoworkers: () => void;
  updateDateRange: (startDate: Moment, endDate: Moment) => void;
  onCreateTask: (newTaskData: TaskFormData) => void;
  createTaskStatus: LoadStatus;
  onUpdateTask: (updatedTaskData: TaskFormData) => void;
  updateTaskStatus: LoadStatus;
  onDeleteTask: (id: string) => void;
  deleteTaskStatus: LoadStatus;
  onCreateShareSchedule: (startDate: Moment, endDate: Moment) => void;
}

function Schedule({
    loadTasks, loadCoworkers, tasks, coworkers, startDate, endDate, updateDateRange,
    onCreateTask, createTaskStatus, onUpdateTask, updateTaskStatus, onDeleteTask, deleteTaskStatus, onCreateShareSchedule
  }: Props) {
  const now = moment();
  const startTime = now.minute() || now.second() || now.millisecond() ? now.add(1, 'hours').startOf('hour') : now.startOf('hour');
  const taskMap = tasks.reduce((map: Map<string, Task>, task: Task) => {
    map.set(task.id, task);
    return map;
  }, new Map());
  const defaultTaskFormData: TaskFormData = {
    startTime,
    endTime: moment(startTime).add(1, "hours"),
    reminderTime: moment(startTime).subtract(15, "minutes"),
    resources: [],
    version: 0,
  };

  const [taskFormOpen, setTaskFormOpen] = React.useState(false);
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [taskFormData, setTaskFormData] = React.useState(defaultTaskFormData);
  const [shareScheduleFormOpen, setShareScheduleFormOpen] = React.useState(false);

  message.config({
    top: 64
  });

  React.useEffect(() => {
    loadTasks(startDate, endDate);
    loadCoworkers();
  }, [loadTasks, loadCoworkers, startDate, endDate]);

  // Handle create task status update
  React.useEffect(() => {
    message.destroy();
    switch (createTaskStatus) {
      case 'Loading':
        message.loading("Creating new task.");
        break;
      case 'Loaded':
        message.success("Task is created successfully!");
        setTaskFormOpen(false);
        break;
      case 'Failed':
        message.error("Create new task failed.");
        break;
    }
  }, [createTaskStatus]);

  // Handle update task status update
  React.useEffect(() => {
    message.destroy();
    switch (updateTaskStatus) {
      case 'Loading':
        message.loading("Updating task.");
        break;
      case 'Loaded':
        message.success("Task is updated successfully!");
        setTaskFormOpen(false);
        break;
      case 'Failed':
        message.error("Update task failed.");
        break;
    }
  }, [updateTaskStatus]);

   // Handle delete task status update
  React.useEffect(() => {
    message.destroy();
    switch (deleteTaskStatus) {
      case 'Loading':
        message.loading("Deleting task.");
        break;
      case 'Loaded':
        message.success("Task is deleted successfully!");
        break;
      case 'Failed':
        message.error("Delete task failed.");
        break;
    }
  }, [deleteTaskStatus]);

  const handleDatesSet = (info: DatesSetArg) => {
    updateDateRange(moment(info.startStr), moment(info.endStr));
  };

  const handleDeleteEvent = (clickEvent: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const id = clickEvent.currentTarget.dataset.event_id!;
    const task = taskMap.get(id);
    if (task) {
      Modal.confirm({
        title: `Are you sure delete this task - ${task.subject}?`,
        icon: <ExclamationCircleOutlined />,
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          onDeleteTask(task.id);
        },
        // tslint:disable-next-line: no-empty
        onCancel() {},
      });
    } else {
      // shouldn't happen
      throw new Error('task is null');
    }
  };

  const handleSelect = (info: DateSelectArg) => {
    const curTaskFormData: TaskFormData = {
      startTime: moment(info.startStr),
      endTime: moment(info.endStr),
      reminderTime: moment(info.startStr).subtract(15, "minutes"),
      resources: [],
      version: 0,
    };
    setTaskFormData(curTaskFormData);
    setIsUpdate(false);
    setTaskFormOpen(true);
  };

  const onNewTaskSubmit = (newTaskData: TaskFormData) => {
    onCreateTask(newTaskData);
  };

  const handleOpenEvent = (clickEvent: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const id = clickEvent.currentTarget.dataset.event_id!;
    const task = taskMap.get(id);
    if (task) {
      const curTaskFormData: TaskFormData = {
        id: task.id,
        subject: task.subject,
        startTime: moment(task.startTime),
        endTime: moment(task.endTime),
        reminderTime: moment(task.reminder.time),
        details: task.details,
        resources: [...task.resources], // this prevent the original array container from been poluted
        version: task.version,
      };
      setTaskFormData(curTaskFormData);
      setIsUpdate(true);
      setTaskFormOpen(true);
    } else {
      // shouldn't happen
      throw new Error('task is null');
    }
  };

  const handleCopyEvent = (clickEvent: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const id = clickEvent.currentTarget.dataset.event_id!;
    const task = taskMap.get(id);
    if (task) {
      const curTaskFormData: TaskFormData = {
        id: task.id,
        subject: task.subject,
        startTime: moment(task.startTime),
        endTime: moment(task.endTime),
        reminderTime: moment(task.reminder.time),
        details: task.details,
        resources: [...task.resources], // this prevent the original array container from been poluted
        version: task.version,
      };
      setTaskFormData(curTaskFormData);
      setIsUpdate(false);
      setTaskFormOpen(true);
    } else {
      // shouldn't happen
      throw new Error('task is null');
    }
  };

  const onUpdateTaskSubmit = (updatedTaskData: TaskFormData, isFieldsTouched: boolean) => {
    if (!isFieldsTouched) {
      setTaskFormOpen(false);
    } else {
      onUpdateTask(updatedTaskData);
    }
  };

  const onCancelNewOrUpdateTask = () => {
    setTaskFormOpen(false);
  };

  const handleShareScheduleClick = () => {
    setShareScheduleFormOpen(true);
  };

  const onShareSchedule = (scheduleStartDate: Moment, scheduleEndDate: Moment) => {
    onCreateShareSchedule(scheduleStartDate, scheduleEndDate);
    setShareScheduleFormOpen(false);
  };

  const onCancelShareSchedule = () => {
    setShareScheduleFormOpen(false);
  };

  const calendarEvents: EventInput[] = tasks.map((task) => ({
    id: task.id,
    title: task.subject,
    start: task.startTime,
    end: task.endTime,
  }));

  const popoverContent = (event: EventApi) => (
    <>
      <Button data-event_id={event.id} onClick={handleCopyEvent}>Copy</Button>
      <Button data-event_id={event.id} onClick={handleOpenEvent}>Update</Button>
      <Button data-event_id={event.id} onClick={handleDeleteEvent}>Delete</Button>
    </>
  );

  const eventView = (eventInfo: EventContentArg) => {
    // console.log("view type is: " + eventInfo.view.type);
    if (eventInfo.view.type !== 'listMonth') {
      return (
        <Popover className={'schedule__popover'} content={popoverContent(eventInfo.event)} trigger={['click']}>
          <div><b>{eventInfo.timeText}</b>{ ` ${eventInfo.event.title}`}</div>
        </Popover>
      );
    }
  };

  return (
    <>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Schedule</Breadcrumb.Item>
      </Breadcrumb>
      <FullCalendar
        plugins={[ interactionPlugin, dayGridPlugin, timeGridPlugin]}
        headerToolbar={{ end: 'today prev,next dayGridMonth,timeGridWeek share'}}
        views= {{
          dayGridMonth: { buttonText: 'Month' },
          timeGridWeek: { buttonText: 'Week'}
        }}
        customButtons = {
          {
            share: {
              text: 'Share',
              click: handleShareScheduleClick,
            }
          }
        }
        nowIndicator={true}
        selectable={true}
        initialView="dayGridMonth"
        height={700}
        events={calendarEvents}
        select={handleSelect}
        datesSet={handleDatesSet}
        fixedWeekCount={false}
        eventContent={eventView}
      />
      <ScheduleFormModal
        isUpdate={isUpdate}
        isOpen={taskFormOpen}
        initialTaskFormData={taskFormData}
        availableCoworkers={coworkers}
        onNewTaskSubmit={onNewTaskSubmit}
        onUpdateTaskSubmit={onUpdateTaskSubmit}
        onCancel={onCancelNewOrUpdateTask}
      />
      <ShareScheduleFormModal
        isOpen={shareScheduleFormOpen}
        defaultStartDate={startDate}
        defaultEndDate={endDate}
        onShareTask={onShareSchedule}
        onCancel={onCancelShareSchedule}
      />
    </>
  );
}

export default Schedule;