import { action } from 'typesafe-actions';
import { EReduxActionTypes, LoadStatus } from '../types';
import { Task } from '../types/task';
import { callAPI } from '../services/dataProxy';
import { Dispatch } from 'redux';
import { TaskFormData } from '../types/taskFormData';
import { AppState } from "../reducers/root";
import moment, { Moment } from 'moment';
import { message } from 'antd';

// Standard Actions

export const scheduleLoading = () => action(EReduxActionTypes.SCHEDULE_LOADING);

export const scheduleLoaded = (tasks: Task[]) => action(EReduxActionTypes.SCHEDULE_LOADED, {
  tasks
});

export const addNewTask = (tasks: Task[]) => action(EReduxActionTypes.SCHEDULE_LOADED, {
  tasks
});

export const setCreateTaskStatus = (status: LoadStatus) => action(
  EReduxActionTypes.SCHEDULE_SET_TASK_CREATE_STATUS,
  {
    status
  }
);

export const setUpdateTaskStatus = (status: LoadStatus) => action(
  EReduxActionTypes.SCHEDULE_SET_TASK_UPDATE_STATUS,
  {
    status
  }
);

export const setDeleteTaskStatus = (status: LoadStatus) => action(
  EReduxActionTypes.SCHEDULE_SET_TASK_DELETE_STATUS,
  {
    status
  }
);

export const updateDateRange = (startDate: moment.Moment, endDate: moment.Moment) => action(
  EReduxActionTypes.SCHEDULE_UPDATE_DATERANGE, {
    startDate,
    endDate,
  }
);

export const shareScheduleLoaded = (tasks: Task[], startDate: moment.Moment, endDate: moment.Moment) => action(
  EReduxActionTypes.SHARE_SCHEDULE_LOADED, {
    tasks,
    startDate,
    endDate,
  }
);

// Async Actions below

export const loadTasks = () => async (dispatch: Dispatch, getState: () => any): Promise<void> => {
  try {
    dispatch(setCreateTaskStatus('Not_Start'));
    dispatch(setUpdateTaskStatus('Not_Start'));
    dispatch(setDeleteTaskStatus('Not_Start'));
    const state: AppState = getState();
    if (state.schedule.tasks.length === 0) {
      // Display loading screen only when no task is loaded.
      dispatch(scheduleLoading());
    }
    const response = await callAPI(
      `/tasks/?startTime=${state.schedule.startDate.toISOString()}&endTime=${state.schedule.endDate.toISOString()}`,
      'GET',
      true);
    if (response.ok) {
      const body = await response.json();
      // convert to task list
      const tasks: Task[] = body.result.Items.map((item: any): Task => ({...item}));
      dispatch(scheduleLoaded(tasks));
    } else {
      // TODO: add error handling
      dispatch(scheduleLoaded([]));
    }
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.error(e);
    // TODO: redirect to error page
  }
};

export const onCreateTask = (newTaskData: TaskFormData) => async (dispatch: Dispatch): Promise<void> => {
  try {
    dispatch(setCreateTaskStatus('Loading'));
    const response = await callAPI('/tasks', 'POST', true, {
      subject: newTaskData.subject,
      startTime: newTaskData.startTime.toISOString(),
      endTime: newTaskData.endTime.toISOString(),
      reminderTime: newTaskData.reminderTime.toISOString(),
      details: newTaskData.details,
      taskResources: newTaskData.resources,
      version: newTaskData.version,
    });
    if (response.ok) {
      dispatch(setCreateTaskStatus('Loaded'));
      dispatch(loadTasks() as any);
    }
    else {
      dispatch(setCreateTaskStatus('Failed'));
    }
  }
  catch (e) {
    // tslint:disable-next-line: no-console
    console.error(e);
    // TODO: redirect to error page
  }
};

export const onUpdateTask = (updatedTaskData: TaskFormData) => async (dispatch: Dispatch): Promise<void> => {
  try {
    dispatch(setUpdateTaskStatus('Loading'));
    const response = await callAPI(`/tasks/${updatedTaskData.id}`, 'PUT', true, {
      subject: updatedTaskData.subject,
      startTime: updatedTaskData.startTime.toISOString(),
      endTime: updatedTaskData.endTime.toISOString(),
      reminderTime: updatedTaskData.reminderTime.toISOString(),
      details: updatedTaskData.details,
      taskResources: updatedTaskData.resources,
      version: updatedTaskData.version ? updatedTaskData.version : 0
    });
    if (response.ok) {
      dispatch(setUpdateTaskStatus('Loaded'));
      dispatch(loadTasks() as any);
    } else {
      dispatch(setUpdateTaskStatus('Failed'));
    }
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.error(e);
    // TODO: redirect to error page
  }
};

export const onDeleteTask = (id: string) => async (dispatch: Dispatch): Promise<void> => {
  try {
    dispatch(setDeleteTaskStatus('Loading'));
    const response = await callAPI(`/tasks/${id}`, 'DELETE', true);
    if (response.ok) {
      dispatch(setDeleteTaskStatus('Loaded'));
      dispatch(loadTasks() as any);
    } else {
      dispatch(setDeleteTaskStatus('Failed'));
    }
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.error(e);
    // TODO: redirect to error page
  }
};

export const loadShareSchedule = (shareId: string) => async (dispatch: Dispatch, getState: () => any): Promise<void> => {
  try {
    const response = await callAPI(
      `/shareschedule/${shareId}`,
      'GET',
      false);
    if (response.ok) {
      const body = await response.json();
      dispatch(shareScheduleLoaded(body.tasks, moment(body.startTime), moment(body.endTime)));
    } else {
      // TODO: add error handling
      dispatch(scheduleLoaded([]));
    }
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.error(e);
    // TODO: redirect to error page
  }
};

export const createShareSchedule = (startDate: Moment, endDate: Moment) => async (dispatch: Dispatch, getState: () => any): Promise<void> => {
  try {
    const response = await callAPI(
      `/shareschedule`,
      'POST',
      true,
      {
        startTime: startDate,
        endTime: endDate,
      });
    if (response.ok) {
      const body = await response.json();
      message.success({
        content: `Schedule is successfull shared: \n\n${window.document.location.origin}/share/${body.id}`,
        duration: 30000, // 30 seconds
      });
    } else {
      // TODO: add error handling
      message.error('Failed to share schedule.');
    }
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.error(e);
    // TODO: redirect to error page
  }
};
