import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from 'redux';
import * as actions from '../actions/user';
import { AppState } from "../reducers/root";
import SignUp from "../components/SignUp";
import { ActionType } from "typesafe-actions";

export type UserAction = ActionType<typeof actions>;

export function mapStateToProps(state: AppState) {
  return {
    signUpStatus: state.user.signUpStatus,
  }
}

export function mapDispatchToProps(dispatch: Dispatch<UserAction>) {
  return bindActionCreators({
    onSubmit: actions.signUp,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);