import { AppState } from "../reducers/root";
import { connect } from "react-redux";
import Team from "../components/Team";
import { ActionType } from "typesafe-actions";
import * as teamActions from '../actions/team';
import { bindActionCreators, Dispatch } from "redux";

export type CoworkerAction = ActionType<typeof teamActions>;

function mapStateToProps(state: AppState) {
  return {
    isLoading: state.team.loading,
    coworkers: state.team.coworkers,
    createCoworkerStatus: state.team.createCoworkerStatus,
    updateCoworkerStatus: state.team.updateCoworkerStatus,
    deleteCoworkerStatus: state.team.deleteCoworkerStatus,
  }
}

export function mapDispatchToProps(dispatch: Dispatch<CoworkerAction>) {
  return bindActionCreators({
    loadCoworkers: teamActions.loadCoworkers,
    onCreateCoworker: teamActions.createCoworker,
    onUpdateCoworker: teamActions.updateCoworker,
    onDeleteCoworker: teamActions.deleteCoworker,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Team);