import { Route, Redirect } from "react-router";
import React, { ReactNode } from "react";
import { AppState } from "../reducers/root";
import { connect } from "react-redux";

export interface Props {
  path: string;
  isAuthenticated: boolean;
  children: ReactNode;
}

function mapStateToProps(state: AppState) {
  return {
    isAuthenticated: !!state.user.userInfo,
  };
}

const ProtectedRoute = ({ isAuthenticated, children, ...rest } : Props) => {
  return (
    <Route
      { ...rest }
      // tslint:disable-next-line: jsx-no-lambda
      render = {({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to = {{
              pathname: '/',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
};

export default connect(mapStateToProps)(ProtectedRoute);