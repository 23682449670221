import * as React from 'react';
import { Link, useHistory, useLocation } from "react-router-dom";
import { Button, Row, Col, Menu } from 'antd';
import './AppBar.css';
import { Constants } from '../types';

export interface Props {
  isAuthenticated: boolean;
  signOut: () => void;
}

function AppBar({ isAuthenticated, signOut }: Props) {
  const history = useHistory();
  const selectedRoute = useLocation().pathname;
  const onSignOut = () => {
    window.localStorage.removeItem(Constants.JWT_TOKEN_KEY)
    signOut();
    history.push('/');
  };

  return (
    <Row>
      <Col flex='150px'>
        <Link className={"appbar-title"} to={"/schedule"}>Litaff</Link>
      </Col>
      {
        isAuthenticated ?
        <Col flex='300px'>
          <Menu theme="dark" mode="horizontal" selectedKeys={[selectedRoute]}>
            <Menu.Item key="/schedule">
              <Link to={"/schedule"}>Schedule</Link>
            </Menu.Item>
            <Menu.Item key="/team">
              <Link to={"/team"}>Team</Link>
            </Menu.Item>
          </Menu>
        </Col> :
        null
      }
      <Col flex='auto' className={"appbar-right-menu"}>
        {
          !isAuthenticated ?
            <Link className={"appbar-right-menu-item"} to={"/login"}>Sign In</Link>
          :
            <Button className={"appbar-right-menu-item"} type="link" onClick={onSignOut}>
              Sign Out
            </Button>
        }
      </Col>
    </Row>
  );
}

export default AppBar;