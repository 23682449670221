import { ActionType } from 'typesafe-actions';
import { EReduxActionTypes, LoadStatus } from '../types';
import * as actions from '../actions/schedule';
import { Task } from '../types/task';
import moment from 'moment';

export type ScheduleState = Readonly<{
  tasks: Task[];
  loading: boolean;
  startDate: moment.Moment;
  endDate: moment.Moment;
  createTaskStatus: LoadStatus;
  updateTaskStatus: LoadStatus;
  deleteTaskStatus: LoadStatus;
}>;

const initialState : ScheduleState = {
  tasks: [],
  loading: false,
  startDate: moment().utc().startOf('month'),
  endDate: moment().utc().endOf('month'),
  createTaskStatus: 'Not_Start',
  updateTaskStatus: 'Not_Start',
  deleteTaskStatus: 'Not_Start',
};

export type ScheduleActions = ActionType<typeof actions>;

export default function schedule(state: ScheduleState = initialState, action: ScheduleActions): ScheduleState {
  switch (action.type) {
    case EReduxActionTypes.SCHEDULE_LOADED:
      return { ...state, tasks: action.payload.tasks, loading: false};
    case EReduxActionTypes.SCHEDULE_LOADING:
      return { ...state, loading: true};
    case EReduxActionTypes.SCHEDULE_UPDATE_DATERANGE:
      return { ...state, startDate: action.payload.startDate, endDate: action.payload.endDate };
    case EReduxActionTypes.SCHEDULE_SET_TASK_CREATE_STATUS:
      return { ...state, createTaskStatus: action.payload.status};
    case EReduxActionTypes.SCHEDULE_SET_TASK_UPDATE_STATUS:
      return { ...state, updateTaskStatus: action.payload.status};
    case EReduxActionTypes.SCHEDULE_SET_TASK_DELETE_STATUS:
      return { ...state, deleteTaskStatus: action.payload.status};
    case EReduxActionTypes.SHARE_SCHEDULE_LOADED:
      return { ...state, tasks: action.payload.tasks, startDate: action.payload.startDate, endDate: action.payload.endDate };
    default:
      return state;
  }
}
