import { ActionType } from 'typesafe-actions';
import { EReduxActionTypes } from '../types';
import * as actions from '../actions/user';
import { UserInfo, SignInStatus, SignUpStatus } from '../types/user';

export type UserState = Readonly<{
  userInfo?: UserInfo;
  token?: string;
  signInStatus: SignInStatus;
  signUpStatus: SignUpStatus;
}>;

const initialState : UserState = {
  userInfo: undefined,
  signInStatus: 'Unknown',
  signUpStatus: 'Unknown',
}

export type UserAction = ActionType<typeof actions>;

export default function user(state: UserState = initialState, action: UserAction): UserState {
  switch (action.type) {
    case EReduxActionTypes.SIGNED_IN:
      return { ...state, userInfo: action.payload.userInfo, signInStatus: 'Success'};
    case EReduxActionTypes.SIGN_IN_FAILED:
      return { ...state, signInStatus: 'Failed'};
    case EReduxActionTypes.SIGNED_UP:
      return { ...state, userInfo: action.payload.userInfo, signUpStatus: 'Success'};
    case EReduxActionTypes.SIGN_UP_FAILED:
      return { ...state, signUpStatus: 'Failed'};
    case EReduxActionTypes.SIGN_OUT:
      return { ...initialState };
    default:
      return state;
  }
}
