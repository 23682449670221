import { AppState } from "../reducers/root";
import { connect } from "react-redux";
import { ActionType } from "typesafe-actions";
import * as scheduleActions from '../actions/schedule';
import * as teamActions from '../actions/team';
import { bindActionCreators, Dispatch } from "redux";
import Schedule from "../components/Schedule";

export type ScheduleAction = ActionType<typeof scheduleActions>;

function mapStateToProps(state: AppState) {
  return {
    isLoading: state.schedule.loading,
    tasks: state.schedule.tasks,
    coworkers: state.team.coworkers,
    startDate: state.schedule.startDate,
    endDate: state.schedule.endDate,
    createTaskStatus: state.schedule.createTaskStatus,
    updateTaskStatus: state.schedule.updateTaskStatus,
    deleteTaskStatus: state.schedule.deleteTaskStatus,
  };
}

function mapDispatchToProps(dispatch: Dispatch<ScheduleAction>) {
  return bindActionCreators({
    loadTasks: scheduleActions.loadTasks,
    loadCoworkers: teamActions.loadCoworkers,
    updateDateRange: (startDate: moment.Moment, endDate: moment.Moment) => dispatch(scheduleActions.updateDateRange(startDate, endDate)),
    onCreateTask: scheduleActions.onCreateTask,
    onUpdateTask: scheduleActions.onUpdateTask,
    onDeleteTask: scheduleActions.onDeleteTask,
    onCreateShareSchedule: scheduleActions.createShareSchedule,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);