import * as React from 'react';
import { Typography } from 'antd';

function GenericNotFound() {
  return (
    <div>
      <Typography>
        Not found
      </Typography>
    </div>
  );
}

export default GenericNotFound;