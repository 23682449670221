import { action } from 'typesafe-actions';
import { EReduxActionTypes, Constants } from '../types';
import { UserInfo } from '../types/user';
import { History } from 'history';
import { Dispatch } from 'redux';

export const signedIn = (userInfo: UserInfo) => action(EReduxActionTypes.SIGNED_IN, {
  userInfo,
});

export const signInFailed = () => action(EReduxActionTypes.SIGN_IN_FAILED);

export const signedUp = (userInfo: UserInfo) => action(EReduxActionTypes.SIGNED_UP, {
  userInfo,
});

export const signUpFailed = () => action(EReduxActionTypes.SIGN_UP_FAILED);

export const signOut = () => action(EReduxActionTypes.SIGN_OUT);

// Async actions below

export const signIn = (history: History<History.PoorMansUnknown>, username: string, password: string) => async (dispatch: Dispatch): Promise<void> => {
  const signinUrl = `${process.env.REACT_APP_BACKEND_HOST}/auth/signin`;
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: username,
      password
    }),
  };
  try {
    const response = await fetch(signinUrl, requestOptions);
    if (response.ok) {
      const body = await response.json();
      dispatch(signedIn(body.userInfo));
      window.localStorage.setItem(Constants.JWT_TOKEN_KEY, body.token);
      history.push('/schedule');
    } else {
      dispatch(signInFailed());
    }
  } catch (e) {
    dispatch(signInFailed());
  }
}

export const signUp = (history: History<History.PoorMansUnknown>, username: string, password: string) => async (dispatch: Dispatch): Promise<void> => {
  // dispatch actions, return Promise, etc.
  const signupUrl = `${process.env.REACT_APP_BACKEND_HOST}/auth/signup`;
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: username,
      password
    }),
  };
  try {
    const response = await fetch(signupUrl, requestOptions);
    if (response.ok) {
      const body = await response.json();
      dispatch(signedUp(body.userInfo));
      window.localStorage.setItem(Constants.JWT_TOKEN_KEY, body.token);
      history.push('/schedule');
    } else {
      dispatch(signUpFailed());
    }
  } catch (e) {
    dispatch(signUpFailed());
  }
}
