import fetch from 'cross-fetch';
import { Constants } from "../types";

export const callAPI = async (path: string, method: string, inlcudeCredential: boolean, requestBody?: object) => {
  if (!path.startsWith('/')) {
    throw new Error("path doesn't start with '/'");
  }
  const endpointUrl = `${process.env.REACT_APP_BACKEND_HOST}${path}`;
  const requestOptions : RequestInit = {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  };
  const jwtToken = window.localStorage.getItem(Constants.JWT_TOKEN_KEY);
  if (inlcudeCredential && jwtToken != null) {
    requestOptions.credentials = 'include';
    requestOptions.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwtToken}`
    };
  }
  if (requestBody) {
    requestOptions.body = JSON.stringify(requestBody);
  }

  try {
    const response = await fetch(endpointUrl, requestOptions);
    if (response.status === 401) {
      window.localStorage.removeItem(Constants.JWT_TOKEN_KEY);
    }
    return response;
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.error(e);
    throw e;
  }
};
