import * as React from 'react';
import { Button, Input, Modal, Form, Select } from 'antd';
import { Coworker, Contact } from '../types/coworker';
import { MinusCircleOutlined } from '@ant-design/icons';
import './TeamFormModal.css';

export interface Props {
  isOpen: boolean;
  isUpdate: boolean;
  coworker?: Coworker;
  onCreateCoworker?: (name: string, contacts: Contact[]) => void;
  onUpdateCoworker?: (id: string, name: string, contacts: Contact[]) => void;
  onCancel: () => void;
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

function TeamFormModal({ isOpen, isUpdate, coworker, onCreateCoworker, onUpdateCoworker, onCancel }: Props) {
  const [form] = Form.useForm();
  const okLabel = isUpdate ? 'Update' : 'Create';
  const title = isUpdate ? "Update Coworker" : "Create New Coworker";

  React.useEffect(() => {
    if (isOpen) {
      form.resetFields();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleOk = () => {
    form.validateFields()
    .then(values => {
      if (isUpdate) {
        onUpdateCoworker!(coworker!.id, values.name, values.contacts);
      } else {
        onCreateCoworker!(values.name, values.contacts);
      }
    })
    .catch(() => {/** ignore */});
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Modal
      title={title}
      centered={true}
      visible={isOpen}
      maskClosable={false}
      closable={false}
      okText={okLabel}
      cancelText='Cancel'
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form
        {...layout}
        name="team_form"
        form={form}
        initialValues={ isUpdate ? coworker : {} }
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            { required: true, message: 'Please input the name.' },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.List name='contacts'>
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field) => (
                  <Form.Item
                    label={`Contact ${field.name}`}
                    required={false}
                    key={field.key}
                  >
                    <Input.Group compact={true}>
                      <Form.Item name={[field.name, 'type']} initialValue={1} noStyle={true}>
                        <Select>
                          <Select.Option value={1}>Email</Select.Option>
                          {/* <Select.Option value={2}>Phone Number</Select.Option> */}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[field.name, 'value']}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "Please input valid contact information.",
                          },
                          {
                            type: 'email',
                            message: "Please input valid email format.",
                          }
                        ]}
                        noStyle={true}
                      >
                        <Input placeholder="" style={{ width: '60%' }} />
                      </Form.Item>
                      <MinusCircleOutlined
                        className={'team-form-modal__remove-contact-button'}
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    </Input.Group>
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                  >
                    Add Contact
                      </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
      </Form>
    </Modal>

  );
}

export default TeamFormModal;